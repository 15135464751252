import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Running a GraphQL service";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "running-a-graphql-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#running-a-graphql-service",
        "aria-label": "running a graphql service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running a GraphQL service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#graphqlservice"
        }}>{`GraphqlService`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#blocking-service-implementation"
        }}>{`Blocking service implementation`}</a></li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`armeria-examples`}</a>{` to find a fully working example.`}</p>
    </Tip>
    <p>{`First, You need the `}<inlineCode parentName="p">{`armeria-graphql`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-graphql'
    }]} mdxType="RequiredDependencies" />
    <p>{`Let's assume we have the following `}<a parentName="p" {...{
        "href": "https://graphql.org/learn/schema/"
      }}>{`GraphQL Schema`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`type Query {
  user(id: ID): User
}

type User {
  id: ID
  name: String
}
`}</code></pre>
    <p>{`The schema describes that if a client requests a `}<inlineCode parentName="p">{`User`}</inlineCode>{` data using the `}<inlineCode parentName="p">{`ID`}</inlineCode>{` field, the GraphQL server will
return the `}<inlineCode parentName="p">{`User`}</inlineCode>{` data. The GraphQL engine uses a `}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.graphql-java/graphql-java/latest/graphql/schema/DataFetcher.html"
      }}>{`DataFetcher`}</a>{`
to resolve the ID and fetch the corresponding object. For the sake of simplicity, let's implement
`}<inlineCode parentName="p">{`UserDataFetcher`}</inlineCode>{` that stores `}<inlineCode parentName="p">{`User`}</inlineCode>{`s in a `}<inlineCode parentName="p">{`Map`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import graphql.schema.DataFetcher;
import graphql.schema.DataFetchingEnvironment;

class UserDataFetcher implements DataFetcher<User> {

    private final Map<String, User> data = Map.of("1", new User("1", "hero"),
                                                  "2", new User("2", "human"),
                                                  "3", new User("3", "droid"));

    @Override
    public User get(DataFetchingEnvironment environment) throws Exception {
        final String id = environment.getArgument("id");
        return data.get(id);
    }
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`Please note that this is entirely based on `}<a parentName="p" {...{
          "href": "https://www.graphql-java.com/"
        }}>{`graphql-java`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "graphqlservice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#graphqlservice",
        "aria-label": "graphqlservice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`GraphqlService`}</inlineCode></h2>
    <p>{`Once you've finished the implementation of the `}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.graphql-java/graphql-java/latest/graphql/schema/DataFetcher.html"
      }}>{`DataFetcher`}</a>{`, you need to build a `}<a parentName="p" {...{
        "href": "type://GraphqlService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlService.html"
      }}>{`type://GraphqlService`}</a>{` using
a `}<a parentName="p" {...{
        "href": "type://GraphqlServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlServiceBuilder.html"
      }}>{`type://GraphqlServiceBuilder`}</a>{` and add it to the `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.graphql.GraphqlService;

ServerBuilder sb = Server.builder();
...
sb.service("/graphql",
           GraphqlService.builder()
                         .runtimeWiring(c -> {
                            c.type("Query",
                                   typeWiring -> typeWiring.dataFetcher("user", new UserDataFetcher()));
                         })
                         .build());
...
Server server = sb.build();
server.start();
`}</code></pre>
    <p>{`We used `}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.graphql-java/graphql-java/latest/graphql/schema/idl/RuntimeWiring.html"
      }}>{`RuntimeWiring`}</a>{` to wire the type with DataFetcher. Please see `}<a parentName="p" {...{
        "href": "https://www.graphql-java.com/documentation/master/schema/#creating-a-schema-using-the-sdl"
      }}>{`Creating a schema using the SDL`}</a>{` to find more wiring examples.`}</p>
    <h2 {...{
      "id": "blocking-service-implementation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#blocking-service-implementation",
        "aria-label": "blocking service implementation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Blocking service implementation`}</h2>
    <p>{`Armeria does not run service logic in a separate thread pool by default. If your service implementation
requires blocking, either run the individual blocking logic in a thread pool, or set
`}<a parentName="p" {...{
        "href": "type://GraphqlServiceBuilder#useBlockingTaskExecutor(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlServiceBuilder.html#useBlockingTaskExecutor(boolean)"
      }}>{`type://GraphqlServiceBuilder#useBlockingTaskExecutor(boolean)`}</a>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` so the service runs in
all service methods and lifecycle callbacks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
sb.service("/graphql",
           GraphqlService.builder()
                         .runtimeWiring(c -> {
                            c.type("Query",
                                   typeWiring -> typeWiring.dataFetcher("user", new UserDataFetcher()));
                         })
                         // All service methods will be run within
                         // the blocking executor.
                         .useBlockingTaskExecutor(true)
                         .build());
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`You can wrap a `}<a parentName="p" {...{
          "href": "https://javadoc.io/doc/com.graphql-java/graphql-java/latest/graphql/schema/DataFetcher.html"
        }}>{`DataFetcher`}</a>{` using `}<a parentName="p" {...{
          "href": "https://javadoc.io/doc/com.graphql-java/graphql-java/latest/graphql/schema/AsyncDataFetcher.html"
        }}>{`AsyncDataFetcher`}</a>{` to run it asynchronously.`}</p>
    </Tip>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      